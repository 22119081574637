import React, { useState, useMemo } from 'react'
import Customer from './customer'
import cx from 'classnames'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl, sanityFileUrl } from '../../../utils/format'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ customers }) => {
  if (!customers || customers.isHide) return null

  const [selectedReview, setSelectedReview] = useState(null)

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.1,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider
  }

  const hidePreview = (e, key) => {
    const video = e.target
    video.play()
    const image = document.querySelector(`[data-id="${key}"]`)
    image.classList.add(styles.imageHidden)
  }

  const modalLoaded = () => {
    const closeBtn = document.querySelector(`.${styles.close}`)
    closeBtn.classList.remove(styles.closeHidden)
  }

  const activeReview = useMemo(() => {
    let review = null

    if (selectedReview) {
      customers.items.map(item => {
        if (item._key === selectedReview) {
          review = item
        }
      })
    }

    return review
  }, [selectedReview])

  return (
    <div className={styles.customers}>
      <div className={styles.wrap}>
        {customers.explain && (
          <div className={styles.explain}>
            <img src={sanityImageUrl(customers.explain.image)} alt={customers.explain.image?.caption} className={styles.explainImage}/>
            <div className={styles.explainWrap}>
              <a href={customers.explain.buttonUrl} target="_blanK" className={styles.explainButton}/>
              <p className={styles.explainTitle}>{customers.explain.title}</p>
              <a href={customers.explain.buttonUrl} target="_blanK" className={styles.explainLink}>
                {customers.explain.buttonText}
              </a>
            </div>
          </div>
        )}

        <div className={styles.items}>
          {customers.items && customers.items.map(item => (
            <Customer 
              key={item._key} 
              item={item} 
              setSelectedReview={setSelectedReview} 
              hidePreview={hidePreview}
              videoPreview={true}
            />
          ))}
        </div>

        <Swiper {...sliderSettings}>
          {customers.items && customers.items.map(item => (
            <SwiperSlide key={item._key}>
              <Customer 
                item={item} 
                setSelectedReview={setSelectedReview} 
                hidePreview={hidePreview}
                videoPreview={false}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {selectedReview && (
        <div className={styles.review}>
          <div className={styles.videoBox}>
            <div className={cx(styles.close, styles.closeHidden)} onClick={() => setSelectedReview(null)} />
            <div className={styles.videoBoxPreview}>
              <div className={styles.loader} />
            </div>
            <video className={styles.videoBoxSrc} playsInline autoPlay onLoadedData={modalLoaded}>
              <source src={sanityFileUrl(activeReview.videoFull)} type="video/mp4" />
            </video>
            <div className={styles.name}>{activeReview.name}</div>
          </div>
        </div>
      )}
    </div>
  )
}
