import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { titleToId } from '../../../utils/format'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Protocol from './protocol'
import styles from './style.module.sass'

export default ({ protocols }) => {
  if (!protocols || protocols.isHide) return null

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.1,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider
  }

  const [activeMenu, setActiveMenu] = useState(0)
  
  const scrollHandler = () => {
    if (!window) return false

    let activeNavIndex = 0
    const sections = document.querySelectorAll(`.${styles.line}`)
    const topBar = document.querySelector('.top-bar')
    const protocols = document.querySelector(`.${styles.protocols}`)

    if (topBar) {
      const scroll = window.scrollY
      const nav = document.querySelector(`.${styles.nav}`)
      const rangeMin = protocols.offsetTop - nav.offsetHeight * 1.5
      const rangeMax = protocols.offsetHeight + protocols.offsetTop - nav.offsetHeight

      if (scroll > rangeMin && scroll < rangeMax) {
        gsap.to(topBar, { height: '0', duration: 0.15 })
      } else {
        gsap.to(topBar, { height: 'auto', duration: 0.15 })
      }
    }

    sections.forEach(section => {
      const sectionTop = section.getBoundingClientRect().top
      if (sectionTop - 120 <= 1) {
        activeNavIndex = parseInt(section.getAttribute('data-id'))
      }
    })

    setActiveMenu(activeNavIndex)
  }

  const navLinkHandler = (e) => {
    e.preventDefault()
    gsap.to(window, {
      scrollTo: document.getElementById(e.target.getAttribute('href')),
      duration: 1.4
    })
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <div>
      <div className={styles.nav}>
        <div className={styles.wrap}>
          <div className={styles.links}>
            {protocols.lines && protocols.lines.filter(item => item.showInNav).map((line, index) => (
              <a onClick={navLinkHandler} href={titleToId(line.title)} className={cx(styles.linksItem, {
                [styles.linksItemActive]: activeMenu === index
              })} key={line._key}>{line.title}</a>
            ))}
          </div>
          {protocols.helpMeDecideUrl && protocols.helpMeDecideText && (
            <div className={styles.help}>
              <Link to={protocols.helpMeDecideUrl} className={styles.helpLink}>
                {protocols.helpMeDecideText}
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className={styles.protocols}>
        <div className={styles.wrap}>
          <div className={styles.items}>
            {protocols.lines && protocols.lines.map((line, index) => (
              <div data-id={index} className={styles.line} key={line._key}>
                <div className={styles.text}>
                  <div className={styles.anchor} id={titleToId(line.title)}/>
                  <div className={styles.textSticky}>
                    <p className={styles.textTitle}>{line.title}</p>
                    <p className={styles.textInfo}>{line.text}</p>
                  </div>
                </div>
                <div className={styles.products}>
                  {line.items && line.items.map(item => <Protocol item={item} key={item._key}/>)}
                </div>
                <div className={styles.mobile}>
                  {line.items && (
                    <>
                      {line.items.length === 1 ? (
                        <Protocol item={line.items[0]} />
                      ) : (
                        <Swiper {...sliderSettings}>
                          {line.items && line.items.map(item => (
                            <SwiperSlide className={styles.sliderItem} key={item._key}>
                              <Protocol item={item} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}