import React, { useState } from 'react'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ table }) => {
  if (!table || table.isHide) return null

  const [safetyModalShow, setSafetyModalShow] = useState(false)
  
  const openSafetyModalHandler = () => {
    setSafetyModalShow(true)
  }

  const closeSafetyModalHandler = () => {
    setSafetyModalShow(false)
  }

  return (
    <div className={styles.table}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.topTitle}>{table.title}</h2>
          {table.importantSafetyModal?.modalText && (
            <div className={styles.topLink} onClick={openSafetyModalHandler}>{table.importantSafetyModal.modalText}</div>
          )}
        </div>

        <div className={cx(styles.row, styles.rowHead)}>
          <div className={styles.col}></div>
          <div className={styles.col}>
            <p className={styles.colHead} dangerouslySetInnerHTML={{ __html: table.title_column_1 }} />
          </div>
          <div className={styles.col}>
            <p className={styles.colHead} dangerouslySetInnerHTML={{ __html: table.title_column_2 }} />
          </div>
          <div className={styles.col}>
            <p className={styles.colHead} dangerouslySetInnerHTML={{ __html: table.title_column_3 }} />
          </div>
        </div>

        {table.tableRows &&
          table.tableRows.map(item => (
            <div className={styles.row}>
              <div className={cx(styles.col, styles.colInfo)}>
                <p className={styles.colTitle}>{item.title}</p>
              </div>
              <div className={styles.col}>
                <p className={styles.colText}>{item.column_1}</p>
              </div>
              <div className={styles.col}>
                <p className={styles.colText}>{item.column_2}</p>
              </div>
              <div className={styles.col}>
                <p className={styles.colText}>{item.column_3}</p>
              </div>
            </div>
          ))}
      </div>

      {safetyModalShow && (
        <div className={styles.safetyModal}>
          <div className={styles.safetyModalBg} onClick={closeSafetyModalHandler} />
          <div className={styles.safetyModalWrap}>
            <div className={styles.safetyModalBox}>
              <div className={styles.safetyModalClose} onClick={closeSafetyModalHandler} />
              <p className={styles.safetyModalTitle}>{table.importantSafetyModal.modalTitle}</p>
              <div className={styles.safetyModalContent} dangerouslySetInnerHTML={{ __html: table.importantSafetyModal.modalContent }} />
            </div>
          </div>
        </div>
      )}       
    </div>
  )
}
